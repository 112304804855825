import { Button, styled, TextField } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";
import useWindowDimensions from "../../utils/windowSize";

//nav-height: 60px
//footer-height: 96px

interface LandingProps {
  width: number | null;
  height: number | null;
}

export const MainContainer = styled("div")`
  width: 100%;
  height: calc(100vh - 60px - 96px);
  color: #ffb310;
  padding: 0 3vw 0 4vw;
  margin-top: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint(
    "medium-",
    `
    padding: 0 3vw;
    height: calc(100svh - 60px);
  `
  )}
`;

export const DesktopContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${breakpoint(
    "medium-",
    `
    display: none; // hides mainContent on mobile screens
  `
  )}
`;

export const ContentWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Placeholder = styled("div")`
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-shrink: 1;

  ${breakpoint(
    "small-",
    `
    width: 1080px;
    height: 1190px;
    `
  )}
`;

export const LeftSide = styled("div")`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  width: fit-content;
`;
export const ConveyorBeltGif = styled("video")`
  width: 100%;
  height: auto;
  max-height: 70%;
  align-self: flex-end;

  ${breakpoint(
    "xxxlarge+",
    `
      max-height: 90%;
    `
  )}

  ${breakpoint(
    "medium-",
    `
      max-height: 100%;
    `
  )}
`;

export const ConveyorBeltImg = styled("img")`
  width: 100%;
  height: auto;
  max-height: 70%;
  align-self: flex-end;

  ${breakpoint(
    "xxxlarge+",
    `
      max-height: 90%;
    `
  )}

  ${breakpoint(
    "medium-",
    `
      max-height: 100%;
    `
  )}
`;

export const LeftContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const RightSide = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

// export const TextHighlight = styled("span")`
//   background: linear-gradient(
//     90deg,
//     #ffb310,
//     #f8c471,
//     #f39c12,
//     #e67e22,
//     #ffb310,
//     #da7d3f,
//     #e7cd3c,
//     #e2b70d,
//     #ffb310,
//     #eede75,
//     #f19b19,
//     #ffb310
//   );
//   background-size: 400%;
//   animation: animate 10s linear infinite;
//   -webkit-text-fill-color: transparent;
//   -webkit-background-clip: text;

//   @keyframes animate {
//     0% {
//       background-position: 0%;
//     }

//     100% {
//       background-position: 400%;
//     }
//   }

//   text-align: left;
//   font-weight: 300;
//   color: white;
//   font-size: 3vh;
//   line-height: 4vh;

//   ${breakpoint(
//     "large+",
//     `
//   font-size: 2.5vw;
//     line-height: 3vw;
//   `
//   )}

//   ${breakpoint(
//     "medium-",
//     `
//     text-align: center;
//   `
//   )}
//     ${breakpoint(
//     "small-",
//     `
//      font-size: 2.5vh;
//     line-height: 3vh;
//   `
//   )}

//   @media (max-height: 1100px) {
//     @media (min-width: 2300px) {
//       font-size: 2.5vw;
//     }
//   }
// `;

export const ContactButton = styled(Button)`
  align-self: left;
  box-sizing: border-box;
  width: fit-content;
  margin: 10px 0;
  align-items: center;
  font-size: 1vw;
  text-align: center;
  border-radius: 30px;
  color: black;
  border: 1px solid #ffb310;
  font-weight: 400;
  background-color: #ffb310;
  padding: 1.5vh;

  ${breakpoint(
    "medium-",
    `
    font-size: 3vw;
  `
  )}
`;

export const MobileContent = styled("div")`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 90%;
  max-width: 100%;

  ${breakpoint(
    "medium-",
    `
    display: flex;
    `
  )}
`;

export const MobileMottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  color: white;
`;

export const MobileGifContainer = styled("div")`
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 75%;
  align-items: center;
  width: fit-content;
`;

export const MobileButtonContainer = styled("div")`
  display: flex;
  padding: 10px;
  gap: 2vw;
  flex-shrink: 1;

  > a > img {
    height: 5vh;
  }
`;

export const HeaderText = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 2.5vw;
  text-transform: uppercase;
  white-space: nowrap;

  ${breakpoint(
    "medium-",
    `
      font-size: 3.5vw;
    `
  )}

  ${breakpoint(
    "small-",
    `
  font-size: 5.5vw;
`
  )}
  ${breakpoint(
    "xxlarge+",
    `
  font-size: 2.2vw;
`
  )}

   ${breakpoint(
    "xxxlarge+",
    `
  font-size: 2.5vw;
`
  )}
`;

export const SmallText = styled("span")`
  font-family: Helvetica;
  color: #595959;
  font-size: 0.8vw;
`;

export const SubHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const SubHeader = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
`;

export const EmailInput = styled(TextField)<{ showTypingAnimation: boolean }>`
  flex-grow: 1;

  & .MuiOutlinedInput-root {
    background-color: #2c2c2c;
    height: 40px;
    padding: 5px;
    color: #f6f6f6;

    & fieldset {
      border-color: #ffb310;
    }
    &:hover fieldset {
      border-color: #ffb310;
    }
    &.Mui-focused fieldset {
      border-color: #ffb310;
    }
    & input {
      color: #f6f6f6;
      font-family: Helvetica;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1rem;
      padding: 5px;
      ${breakpoint(
        "small-",
        `
          font-size: 16px; 
          -webkit-text-size-adjust: 100%; /* Prevent zooming on focus */
          touch-action: manipulation; 
        `
      )}
    }
    & input::placeholder {
      color: #595959 !important;
      opacity: 100%;
      font-family: Helvetica;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1rem;

      ${breakpoint(
        "small-",
        `
          font-size: 16px; 
        `
      )}
    }

    ${breakpoint(
      "small-",
      `
        padding: 2px;     
      `
    )}
  }
`;

export const GetStartedButton = styled(Button)<{ isSubmitted: boolean }>`
  width: 200px;
  height: 40px;
  margin-left: 10px;
  color: black;
  background-color: #ffb310;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 1.5rem;
  padding: 0;

  &:hover {
    background-color: #ffb310;
    opacity: 0.9;
  }
  &:disabled {
    color: #ffb310;
    background-color: black;
    border: 1px solid #ffb310;
  }

  ${breakpoint(
    "small-",
    `
      width: 80px;
      font-size: 11px;
      line-height: 15px;
    `
  )}
  ${({ isSubmitted }) =>
    isSubmitted &&
    `
      color: #ffb310;
      background-color: black;
      border: 1px solid #ffb310;
    `}
`;

export const EmailContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const SubHeaderText = styled("div")`
  color: #595959;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;
  text-align: left;
  width: 100%;
  padding: 10px;

  ${breakpoint(
    "small-",
    `
      padding: 10px;
      font-size: 8px;
    `
  )}
`;

export const ErrorText = styled("div")`
  padding: 10px 10px 0px;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;
  color: red;
`;

export const EmailForm = styled("form")`
  padding: 10px 0px;
  ${breakpoint(
    "small-",
    `
    padding: 10px 0px;  
    width: 100%;
  `
  )}
`;
